import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/ServicesPage/Header"
import DescriptionComponent from "../../components/ServicesPage/DescriptionComponent"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import ServicesList from "../../components/ServicesPage/ServicesList"

const ServicesPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"
  const query = useStaticQuery(graphql`
    query Services {
      polish: contentfulServices(node_locale: { eq: "pl-PL" }) {
        service1
        field_text {
          raw
        }
        service2
        service3
        service4
        service5
        service6
        services
        slider1
      }
      english: contentfulServices(node_locale: { eq: "en-US" }) {
        service1
        field_text {
          raw
        }
        service2
        service3
        service4
        service5
        service6
        services
        slider1
      }
    }
  `)

  useEffect(() => {
    if (lang === "en") {
      navigate("/services/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  return (
    <Layout>
      <Seo title="Usługi" />
      <Header slider1={data.slider1} />
      <DescriptionComponent field_text={data.field_text} />
      <ServicesList
        service1={data.service1}
        service2={data.service2}
        service3={data.service3}
        service4={data.service4}
        service5={data.service5}
        service6={data.service6}
        services={data.services}
      />
    </Layout>
  )
}

export default ServicesPage
